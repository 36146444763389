import React, { Component } from 'react';
import PlayerCard from '../gameplay/PlayerCard';
import AppContext from '../AppContext';
import Die from '../gameplay/Die';
import AnnouncementSystem from '../gameplay/AnnouncementSystem/AnnouncementSystem';
import Controls from '../gameplay/Controls/Controls';
import {textColors} from "../DiceImages/diceColors.js";
import { IoChatbubbleSharp } from "react-icons/io5";


class Lobby extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            game: null,
            socket: null,
            bid: [1,0],
            bidCalled: false,
            calzaCalled: false,
            loserId: null,
            calzaSuccess: false,
            nextRoundGame: null,
            resetDieAnimation: false,
            gameOver: false,
            winnerId: null,
            winnerName: null,
            calzaCallerId: null,
            announcementMessages: [],
            lastRoundNumber: null,
            muted: false,
            chatMessage: "",
            isAnnouncementActive: false,
            clientTurnTimer: 0,
            isTimerResetting: false,
            mobileChatVisibile: false,
        };
        this.apiUrl = process.env.REACT_APP_API_URL;
        this.bidMadeSound = new Audio('/sounds/bidMade.wav');
        this.roundStartSound = new Audio('/sounds/roundStart.mp3');
        this.bidCalledSound = new Audio('/sounds/bidCalled.flac');
        this.neutralRoundEndSound = new Audio('/sounds/neutralRoundEnd.wav');
        this.bidSuccessSound = new Audio('/sounds/bidSuccess.mp3');
        this.callSuccessSound = new Audio('/sounds/callSuccess.mp3');
        this.calzaSuccessSound = new Audio('/sounds/calzaSuccess.wav');
        this.calzaFailSound = new Audio('/sounds/calzaFail.mp3');
        this.yourTurnSound = new Audio('/sounds/yourTurn.mp3');
        this.palafico1Sound = new Audio('/sounds/palafico1.mp3');
        this.palafico2Sound = new Audio('/sounds/palafico2.mp3');
        this.palafico3Sound = new Audio('/sounds/palafico3.mp3');
    }
   
    componentDidUpdate(prevProps, prevState) {
        //find the active player
        const activePlayer = this.state.game?.players.find(player => player.id === this.state.game?.activePlayerId);
        //find the lastActivePlayer
        const lastActivePlayer = this.state.game?.players.find(player => player.id === this.state.game?.lastActivePlayerId);
        //find the totalMatchingDice
        const totalMatchingDice = this.state.game?.players.reduce((total, player) => total + player.dice.filter(die => (!this.state.game?.palaficoRound && die === 1) || die === this.state.game?.bid[1]).length, 0);

        // Handle palafico round announcement
        if (this.state.palaficoRound && this.state.game?.roundNumber !== this.state.lastRoundNumber) {
            const palaficoMessage = () => (
                <span>{activePlayer?.username} has only 1 die for the first time. Palafico!</span>
            );
        
            this.setState({
                announcementMessages: [{ content: palaficoMessage, duration: 500, endOfRound: false }],
                lastRoundNumber: this.state.game?.roundNumber,
                isAnnouncementActive: true,
            });
        }

        if (this.state.bidCalled && !prevState.bidCalled) {
            const bidMessage = () => (
                <div>
                    <span>{lastActivePlayer?.username} bid {this.state.game?.bid[0]}{' '}</span>
                    <Die dieValue={this.state.game?.bid[1]} extraClass='callDie' fillColor="#e8e3e4"/>{' '}
                    <span>, {totalMatchingDice >= this.state.game?.bid[0] ? 'and' : 'but'} there were{' '}
                    {totalMatchingDice >= this.state.game?.bid[0] ? '' : 'only '}{totalMatchingDice} </span>
                </div>
            );
        
            this.setState({
                announcementMessages: [{ content: bidMessage, duration: 500, endOfRound: true }],
                isAnnouncementActive: true,
            });
        }
        else if (!this.state.bidCalled && prevState.bidCalled) 
        {
            this.setState({isTimerResetting: true, clientTurnTimer: this.state.game.turnTimer}, () => {
                this.setState({
                    isTimerResetting: false
                });
            });
        }

        if (this.state.playerTimedOut && !prevState.playerTimedOut) {
            const timeOutMessage = () => (
                <div>
                    <span>{activePlayer?.username} ran out of time.</span>
                </div>
            );
        
            this.setState({
                announcementMessages: [{ content: timeOutMessage, duration: 500, endOfRound: true }],
                isAnnouncementActive: true,
            });
        }
        else if (!this.state.playerTimedOut && prevState.playerTimedOut) 
        {
            this.setState({isTimerResetting: true, clientTurnTimer: this.state.game.turnTimer}, () => {
                this.setState({
                    isTimerResetting: false
                });
            });
        }

        if (this.state.calzaCalled && !prevState.calzaCalled) {
            const calzaResultMessage = () => (
                <div>
                    <span>{lastActivePlayer?.username} bid {this.state.game?.bid[0]}{' '}</span>
                    <Die dieValue={this.state.game?.bid[1]} extraClass='callDie' fillColor="#e8e3e4"></Die>{' '}
                    <span>, {totalMatchingDice === this.state.game?.bid[0] ? 'and ' : 'but '} there were{' '}
                    {totalMatchingDice === this.state.game?.bid[0] ? 'exactly ' : ''}{totalMatchingDice}</span>
                </div>
            );

            this.setState({
                announcementMessages: [
                    { content: calzaResultMessage, duration: 500, endOfRound: true }
                ],
                isAnnouncementActive: true,
            });
        }
        if (this.state.game?.turnTimer !== this.state.clientTurnTimer && this.state.game?.activePlayerId !== prevState.game?.activePlayerId) {
            this.setState({ isTimerResetting: true, clientTurnTimer: this.state.game.turnTimer}, () => {
                // Now reset the isTimerResetting flag
                this.setState({
                    isTimerResetting: false
                });
            });
        }   
    }

    resetTimer() {
        this.setState({ isTimerResetting: true }, () => {
            // Now set the actual timer value and reset the isTimerResetting flag
            this.setState({
                clientTurnTimer: this.props.game.maxTurnTime, // Reset to the max turn time
                isTimerResetting: false
            });
        });
    }
    
    handleAllMessagesDisplayed = () => {
        const lastMessage = this.state.announcementMessages[this.state.announcementMessages.length - 1];
    
        if (lastMessage && lastMessage.endOfRound) {
            // Reset game-related states after end-of-round messages
            this.resetGameStateForNewRound();
        }
        //re-enable controls when announcements are done
        this.setState({ isAnnouncementActive: false });
    };

    resetGameStateForNewRound = () => {
        // Reset game-related states after all messages have been displayed
        //leave the chat history as whatever it was previously
        this.setState(prevState => ({
            game: {
                ...prevState.nextRoundGame,
                chatHistory: prevState.game.chatHistory,
            },
            calzaCalled: false, 
            calzaSuccess: false, 
            lastActivePlayerId: null, 
            calzaCallerId: null, 
            loserId: null, 
            nextRoundGame: null, 
            palaficoRound: prevState.nextRoundGame.palaficoRound, 
            resetDieAnimation: true,
            bidCalled: false,
            playerTimedOut: false,
        }), () => {
            this.setNextValidBid();
            if (!this.state.gameOver) {
                this.roundStartSound.play();
            }
            if (this.state.palaficoRound) {
                this.playPalaficoSound();
            }
            // Reset die animation after a delay
            setTimeout(() => {
                this.setState({ resetDieAnimation: false });
            }, 1);
        });
        
    };

    componentDidMount() {
        const { token, joinGame, userId, socket } = this.context;

        fetch(`${this.apiUrl}/getOrCreateGame`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
        },
        })
        .then(response => {
            if (response.status === 403) {
                this.context.logout();
            }
            return response.json();
        })
        .then(data => {
            this.setState({ game: data.game}, ()=>
            {
                this.setNextValidBid();
            });
            joinGame(data.game.joinCode);
            socket.emit('joinLobby', { gameId: data.game.joinCode, userId });
            socket.on('playerJoined', (data) => {
                console.log("Player joined:");
                console.log(data.newPlayer);
                this.setState(prevState => {
                    // Check if the new player is already in the players array
                    const playerExists = prevState.game.players.some(player => player.id === data.newPlayer.id);
                    
                    if (!playerExists) {
                        // If the player does not exist, add them to the array
                        return {
                            game: {
                                ...prevState.game, // Preserve other properties of the game object
                                players: [...prevState.game.players, data.newPlayer]  // Update the players array
                            }
                        };
                    } else {
                        // If the player already exists, just return the current state
                        return prevState;
                    }
                });
            });

            socket.on('messagePosted', (data) => {
                console.log("Message posted");
                console.log(data.userId);
                console.log(data.chatMessage);
                this.setState(prevState => {
                    // find the player who sent the message
                    const player = prevState.game.players.find(player => player.id === data.userId);
                    const newChatMessage = {
                        playerName: player.username,
                        text: data.chatMessage
                    };
                    prevState.game.chatHistory.push(newChatMessage);
                    return {
                        game: {
                            ...prevState.game, // Preserve other properties of the game object
                            chatHistory: prevState.game.chatHistory,                           
                        }
                    };
                });
            });

            socket.on('playerLeft', (data) => {
                console.log("Player left:");
                console.log(data.userId);
                this.setState(prevState => {
                    // find the player who left in the array
                    const playerIndex = prevState.game.players.findIndex(player => player.id === data.userId);
                    if (playerIndex !== -1) {
                        // If the player exists, remove them from the array
                        prevState.game.players.splice(playerIndex, 1);
                    }
                    return {
                        game: {
                            ...prevState.game, // Preserve other properties of the game object
                            players: prevState.game.players,  // Update the players array
                            hostPlayerId: data.userId === prevState.game.hostPlayerId ? prevState.game.players[0].id : prevState.game.hostPlayerId // Update the hostPlayerId either current host or new if host left
                        }
                    };
                });
            });

            socket.on('playerReadyChange', ({ userId , newStatus}) => {
                console.log(`Player ${userId} ready is now ${newStatus}`);
                this.setState(prevState => {
                    // Update the players array within the game object
                    const updatedPlayers = prevState.game.players.map(player => {
                        if (player.id === userId) {
                            // Toggle the 'ready' state for the current user
                            return { ...player, ready: newStatus };
                        }
                        return player;
                    });
            
                    return {
                        game: {
                            ...prevState.game, // Preserve other properties of the game object
                            players: updatedPlayers  // Update the players array
                        }
                    };
                });
            });

            socket.on('turnTimerToggled', ({turnTimerEnabled}) => {
                console.log(`Turn timer is now ${turnTimerEnabled}`);
                this.setState(prevState => {
                    return {
                        game: {
                            ...prevState.game, // Preserve other properties of the game object
                            turnTimerEnabled: turnTimerEnabled  // Update the turnTimerEnabled
                        }
                    };
                });
            });

            socket.on('playerColorChanged', ({ userId , colorIndex}) => {
                this.setState(prevState => {
                    // Update the players array within the game object
                    const updatedPlayers = prevState.game.players.map(player => {
                        if (player.id === userId) {
                            // change the color for the  user
                            return { ...player, colorIndex: colorIndex };
                        }
                        return player;
                    });
            
                    return {
                        game: {
                            ...prevState.game, // Preserve other properties of the game object
                            players: updatedPlayers  // Update the players array
                        }
                    };
                });
            });

            socket.on('gameStarted', (data) => {
                this.roundStartSound.play();
                console.log(data.game);
                this.setState({ game: data.game});
            });

            socket.on('gameAborted', (game) =>{
                this.setState({ game: game});
                console.log('game aborted');
            });

            socket.on('kicked', (data) => {
                console.log("You were kicked from the game");
                this.context.leaveGame();
                // Update the UI to navigate to home
                this.props.updateSelectedMenuItem('Home');
            });

            socket.on('bidMade', (data) => {
                this.setState({ game: data.game, lastActivePlayerId: data.game.lastActivePlayerId}, ()=>
                {
                    this.setNextValidBid();
                    if (data.game.activePlayerId === this.context.userId)
                    {
                        this.yourTurnSound.play();
                    }
                    this.bidMadeSound.play();
                });
            });

            socket.on('bidCalled', (data) => {
                if (data.snapshot.lastActivePlayerId === this.context.userId && data.loserId !== this.context.userId)
                {
                    this.bidSuccessSound.play();
                }
                else if (data.snapshot.activePlayerId === this.context.userId && data.loserId !== this.context.userId)
                {
                    this.callSuccessSound.play();
                }
                else if (data.loserId === this.context.userId)
                {
                    this.bidCalledSound.play();
                }
                else
                {
                    this.neutralRoundEndSound.play();
                }

                // find the winners username
                const winnerPlayer = data.snapshot.players.find(player => player.id === data.winnerId);
                this.setState({ game: data.snapshot, bidCalled: true, loserId: data.loserId, nextRoundGame: data.game, gameOver: data.gameOver, winnerId: data.winnerId, winnerName: winnerPlayer?.username});
            });

            socket.on('playerTimedOut', (data) => {
                // set the state variable playerTimedOut to true, and the nextRoundGame to data.game, and the loserId to data.loserId
                this.bidCalledSound.play();
                const winnerPlayer = data.game.players.find(player => player.id === data.winnerId);
                this.setState({playerTimedOut: true, nextRoundGame: data.game, loserId: data.playerId, winnerId: data.winnerId, winnerName: winnerPlayer?.username, gameOver: data.gameOver});
            });

            socket.on('calzaCalled', (data) => {
                if (data.success)
                {
                    this.calzaSuccessSound.play();
                }
                else
                {
                    this.calzaFailSound.play();
                }
                this.setState({ game: data.snapshot, calzaCalled: true, nextRoundGame: data.game, calzaSuccess: data.success, loserId: data.success ? null : data.userId, calzaCallerId: data.userId });
            });
        })
        .catch(error => console.error('Error:', error));

        this.timerInterval = setInterval(() => {
            //if turn timer is not enabled, do nothing
            if (!this.state.game?.turnTimerEnabled)
            {
                return;
            }
            //get active player
            const activePlayer = this.state.game?.players.find(player => player.id === this.state.game?.activePlayerId);
            if (activePlayer && !this.state.bidCalled && !this.state.playerTimedOut && !this.state.calzaCalled)
            {
                this.setState(prevState => {
                    console.log(`Client turn timer: ${prevState.clientTurnTimer}`);
                    console.log(`time bank: ${activePlayer?.timeBank}`);
                    if (prevState.clientTurnTimer > 0) {
                        // Decrement the turn timer
                        return { clientTurnTimer: prevState.clientTurnTimer - 1 };
                    } else if (activePlayer?.timeBank > 0 ) {
                        // Turn timer is 0, use the time bank
                        //update the players time bank and the turn this.timerInterval, taking the whole bank
                        return { clientTurnTimer: activePlayer.timeBank - 1, game: {...prevState.game, players: prevState.game.players.map(player => player.id === activePlayer.id ? {...player, timeBank: 0} : player)}};
                    }
                    // No more time in the time bank, stop the interval
                    return {};
                });
            }
        }, 1000);
    }

    // this will randomly choose a palfico round sound to play, 45% for 1, 45% for 2, 10% for 3
    playPalaficoSound = () => 
    {
        const random = Math.random();
        if (random < 0.45)
        {
            this.palafico1Sound.play();
        }
        else if (random < 0.9)
        {
            this.palafico2Sound.play();
        }
        else
        {
            this.palafico3Sound.play();
        }
    }

    setNextValidBid = () => {
        if (!this.state.game) {
            return;
        }
    
        const lastBid = this.state.game.bid; // [number, face]
        const totalDice = this.state.game.players?.reduce((total, player) => total + player.dice.length, 0);
        // check if current player has 1 die
        const playerHasOneDie = this.state.game.players?.find( player => player.id === this.context.userId).dice.length === 1;
        let nextBid;

        if (this.state.game.palaficoRound) {
            // For Palafico rounds, treat all numbers the same
            if (lastBid[1] === 0) {
                nextBid = [1, 0]; // First player in the round
            } 
            else 
            {
                if (playerHasOneDie)
                {
                    if (lastBid[1] === 6 && lastBid[0] === totalDice) {
                        nextBid = [lastBid[0], 0]; // If the last bid was the maximum number of 6's, set to same number of 0's
                    } else if (lastBid[1] === 6) {
                        nextBid = [lastBid[0] + 1, 1]; // If last bid was on 6's, increase number and reset face to 1
                    } else {
                        nextBid = [lastBid[0], lastBid[1] + 1]; // For faces 1-5, increase face by 1 and keep number same
                    }
                }
                else
                {
                    if (lastBid[0] === totalDice) {
                        nextBid = [lastBid[0], 0]; // If the last bid was the maximum number set to same number of 0's
                    }
                    else
                    {
                        nextBid = [lastBid[0] + 1, lastBid[1]]; // increase number by 1 and keep face same
                    }
                }
            }
        } else

        if (lastBid[1] === 0) {
            nextBid = [1, 0]; // First player in the round
        } else if (lastBid[1] === 1) {
            if (lastBid[0] >= Math.ceil(totalDice / 2)) {
                nextBid = lastBid[0] === totalDice ? [lastBid[0], 0] : [lastBid[0] + 1, 1];
            } else {
                nextBid = [lastBid[0] + 1, 1]; // Increase number, keep face 1
            }
        } else if (lastBid[1] === 6 && lastBid[0] === totalDice) {
            nextBid = [lastBid[0], 0]; // If the last bid was the maximum number of 6's, set to same number of 0's
        } else if (lastBid[1] === 6) {
            nextBid = [lastBid[0] + 1, 2]; // If last bid was on 6's, increase number and reset face to 1
        } else {
            nextBid = [lastBid[0], lastBid[1] + 1]; // For faces 2-5, increase face by 1 and keep number same
        }
    
        this.setState({ bid: nextBid });
    };
    
    componentWillUnmount() {
        // Disconnect the socket when the component unmounts
        if (this.state.socket) {
        this.state.socket.disconnect();
        }
        clearInterval(this.timerInterval);
    }

    handlePlayerReady = () => {
        const { socket, gameId, userId } = this.context;
        socket.emit('playerReadyChange', { gameId, userId });
    
        this.setState(prevState => {
            // Update the players array within the game object
            const updatedPlayers = prevState.game.players.map(player => {
                if (player.id === userId) {
                    // Toggle the 'ready' state for the current user
                    return { ...player, ready: !player.ready };
                }
                return player;
            });
    
            return {
                game: {
                    ...prevState.game, // Preserve other properties of the game object
                    players: updatedPlayers  // Update the players array
                }
            };
        });
    };

    toggleTurnTimer = () => 
    {
        const { socket, gameId, userId } = this.context;
        socket.emit('toggleTurnTimer', { gameId, userId });
    }

    handleChangeChatMessage = (event)  => {
        this.setState({chatMessage: event.target.value});
    }

    changeColorLeft = () => {
        const { socket, gameId, userId } = this.context;
        //find the currentPlayer
        const currentPlayer = this.state.game?.players.find(player => player.id === userId);
        // find the next avialable color index that another player is not using (or 0, if all colors are in use) (currently, 0-5 are supported)
        let nextColorIndex = (currentPlayer.colorIndex + 1) % 13;
        //not actually an issue, not sure why linter thinks it is
        // eslint-disable-next-line no-loop-func
        while ( nextColorIndex !== 0 && this.state.game?.players.some(player => player.colorIndex === nextColorIndex))
        {
            nextColorIndex = (nextColorIndex + 1) % 13;
        }
        if (nextColorIndex !== currentPlayer.colorIndex)
        {
            socket.emit('playerColorChange', { gameId, userId, colorIndex: nextColorIndex });
        }
    }

    changeColorRight = () => {
        const { socket, gameId, userId } = this.context;
        //find the currentPlayer
        const currentPlayer = this.state.game?.players.find(player => player.id === userId);
        // find the next avialable color index that another player is not using (or 0, if all colors are in use) (currently, 0-5 are supported)
        let nextColorIndex = (currentPlayer.colorIndex - 1 + 13) % 13;
        //not actually an issue, not sure why linter thinks it is
        // eslint-disable-next-line no-loop-func
        while ( nextColorIndex !== 0 && this.state.game?.players.some(player => player.colorIndex === nextColorIndex))
        {
            nextColorIndex = (nextColorIndex - 1 + 13) % 13;
        }
        if (nextColorIndex !== currentPlayer.colorIndex)
        {
            socket.emit('playerColorChange', { gameId, userId, colorIndex: nextColorIndex});
        }
    }

    handlePlayAgain = () => {
        this.setState({gameOver: false});
    }

    postMessage = () => {
        const { socket, gameId, userId } = this.context;
        if (this.state.chatMessage !== ""){
            socket.emit('postMessage', { userId, gameId, chatMessage: this.state.chatMessage })
            this.setState({chatMessage: ""});
        };
    }

    makeBid = () => {
        const { socket, gameId, userId } = this.context;
        socket.emit('makeBid', { userId, gameId, bid: this.state.bid });
    };

    callBid = () => {
        const { socket, gameId, userId } = this.context;
        socket.emit('callBid', { userId, gameId});
    };
    
    callCalza = () => {
        const { socket, gameId, userId } = this.context;
        socket.emit('callCalza', { userId, gameId});
    };
    
    leaveGame = () => 
    {
        const { socket, gameId, userId } = this.context;
        socket.emit('leaveGame', { gameId, userId });
        this.context.leaveGame();
        // Update the UI to navigate to home
        this.props.updateSelectedMenuItem('Home');
    }

    startGame = () => {
        const { socket, gameId, userId } = this.context;
        socket.emit('startGame', { gameId, userId });
    };

    abortGame = () =>
    {
        //send a post request to abortGame with the joincode in the request paramsts
        const { token } = this.context;
        fetch(`${this.apiUrl}/abortGame/${this.context.gameId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${token}`,
            },
        })
        .then(response => {
            if (response.status === 403) {
                this.context.logout();
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
        })
        .catch(error => console.error('Error:', error));
    }

    kickPlayer = (playerId) => 
    {
        console.log(`Kicking player ${playerId}`);
        //post to kickPlayerFromGame with the joincode in the request params and the player id in the body
        const { token } = this.context;
        fetch(`${this.apiUrl}/kickPlayerFromGame/${this.context.gameId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${token}`,
            },
            body: JSON.stringify({playerId: playerId}),
        })
        .then(response => {
            if (response.status === 403) {
                this.context.logout();
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
        })
        .catch(error => console.error('Error:', error));
    }

    increaseBidNumber = () => {
        //check if the bid number is < the current number of dice among all players in the game
        if (this.state.bid[0] < this.state.game?.players?.reduce((total, player) => total + player.dice.length, 0))
        {
            this.setState({bid: [this.state.bid[0] +1, this.state.bid[1]]});
        }  
    };

    decreaseBidNumber = () => {
        const currentBid = this.state.bid;
        const previousBid = this.state.game.bid;
        let minValidBid;

        if (this.state.game.palaficoRound)
        {
            minValidBid = currentBid[1] > previousBid[1] ? previousBid[0] : previousBid[0] + 1;
        }
        else
        {
            if (previousBid[1] === 0){
                // If the previous bid face was 0, minimum is 1
                minValidBid = 1;
            } else if (currentBid[1] === 1) {
                // Special rule for bidding 1's
                if (previousBid[1] === 1) {
                    // If the previous bid was also 1's, can just increase by 1
                    minValidBid = previousBid[0] + 1;
                } else {
                    // Otherwise, minimum is half the previous bid number rounded up
                    minValidBid = Math.ceil(previousBid[0] / 2);
                }
            } else if (previousBid[1] === 1) {
                // If the previous bid face was 1 and the current is not 1
                minValidBid = (previousBid[0] * 2) + 1;
            } else {
                // For other cases
                minValidBid = currentBid[1] > previousBid[1] ? previousBid[0] : previousBid[0] + 1;
            }
        }
    
        // Only decrease if the result is still above or equal to the minimum valid bid
        if (currentBid[0] > minValidBid) {
            this.setState({bid: [currentBid[0] - 1, currentBid[1]]});
        }
    };
    

    setBidFace = (face) => {
        const currentGame = this.state.game;
        const currentGameBid = currentGame.bid;
        const isPalaficoRound = currentGame.palaficoRound;
        let newBidNumber;
    
        if (isPalaficoRound) {
            // For Palafico rounds, treat all numbers the same
            newBidNumber = face > currentGameBid[1] ? currentGameBid[0] : currentGameBid[0] + 1;
        } else {
            // Existing logic for normal rounds
            if (face === 1) {
                if (currentGameBid[1] === 1) {
                    newBidNumber = currentGameBid[0] + 1;
                } else {
                    newBidNumber = Math.ceil(currentGameBid[0] / 2);
                }
            } else if (currentGameBid[1] === 1) {
                newBidNumber = (currentGameBid[0] * 2) + 1;
            } else {
                newBidNumber = face > currentGameBid[1] ? currentGameBid[0] : currentGameBid[0] + 1;
            }
    
            // Additional check for non-Palafico rounds
            if (face !== 1 && this.state.bid[0] >= newBidNumber) {
                newBidNumber = this.state.bid[0];
            }
        }
    
        // Update the state with the new bid number and face
        this.setState({ bid: [newBidNumber, face] });
    };

    muteOrUnmute = () => {
        this.setState({muted: !this.state.muted});
    }

    toggleMobileChatVisiblity = () => {
        this.setState({mobileChatVisibile: !this.state.mobileChatVisibile});
    }

    getPlayerInContext = () =>
    {
        const { players } = this.state;
        const { userId } = this.context;
        return players.find(player => player.id === userId);
    }

    render() {
        const { game } = this.state;
        const { userId } = this.context;
        const currentPlayer = game?.players.find(player => player.id === userId);
        const currentPlayerIndex = game?.players.findIndex(player => player.id === userId);
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                this.postMessage();
            }
        };
        //check if total dice are less than 80% of original dice
        const totalDice = game?.players?.reduce((total, player) => total + player.dice.length, 0);
        const originalDice = game?.allPlayersAtStart.length * 5;
        const dicePercentage = totalDice / originalDice;
        const abortDisabled = dicePercentage < .8; 

        const reorderedPlayers = game ? [
            ...game.players.slice(currentPlayerIndex),
            ...game.players.slice(0, currentPlayerIndex)
        ] : null;

        return (
        <div>
            { game?.activePlayerId !== null && userId === game?.hostPlayerId && <button disabled={abortDisabled} className="button btn-danger abort-button" onClick={this.abortGame}>Abort Game</button>}
            <div id="btn-toggle-chat" style= {{position:'absolute', top:'calc(92vmin - 10px)', left:'10px'}} onClick={this.toggleMobileChatVisiblity}>
              <IoChatbubbleSharp style= {{height: '8vmin', width:'8vmin'}}></IoChatbubbleSharp>
            </div>
            <div className="feature-card" id="table">
                {reorderedPlayers?.map((player, index) => {
                    let position = (index) % 6;
                    return (<PlayerCard 
                        key={index}
                        userName={player.username}
                        hosting={player.id === this.state.game?.hostPlayerId}
                        active={player.id === this.state.game?.activePlayerId && !this.state.bidCalled}
                        position={position}
                        ready={player.ready}
                        dice={player.dice}
                        lastBid={player.lastBid}
                        //player who made the last bid is the villain
                        villain = {(player.lastBid[0] === this.state.game?.bid[0] && player.lastBid[1] === this.state.game?.bid[1]) ? true : false}
                        started = {(this.state.game?.activePlayerId) ? true : false}
                        bidCalled = {this.state.bidCalled}
                        calzaCalled = {this.state.calzaCalled}
                        calzaSuccess = {this.state.calzaSuccess && player.id === this.state.calzaCallerId}
                        gameBid={this.state.game?.bid}
                        palafico = {this.state.game?.palaficoRound}
                        loser= {this.state.loserId === player.id}
                        resetDieAnimation = {this.state.resetDieAnimation}
                        winner = {!this.state.bidCalled && !this.state.playerTimedOut && this.state.gameOver && player.id === this.state.winnerId}
                        isCurrentPlayer = {currentPlayer?.id === player.id}
                        colorIndex = {player.colorIndex}
                        currentIsHost = {currentPlayer?.id === this.state.game?.hostPlayerId}
                        gameStarted = {this.state.game?.activePlayerId != null}
                        turnTimer = {player.id === this.state.game?.activePlayerId && !this.state.bidCalled ? this.state.clientTurnTimer : 0}
                        timeBank = {player.timeBank}
                        isTimerResetting = {this.state.isTimerResetting}
                        turnTimerEnabled = {this.state.game?.turnTimerEnabled}
                        playerTimedOut = {this.state.playerTimedOut && player.id === this.state.loserId}
                        changeColorLeft = {this.changeColorLeft}
                        changeColorRight = {this.changeColorRight}
                        kickPlayer = {() => this.kickPlayer(player.id)}
                    />)
                })}
                
                <div className="center-content">
                    {!game?.activePlayerId && !this.state.gameOver && 
                        <div className='inverse-feature-card game-options'>
                            <div style={{fontSize:"large", fontWeight:"700"}}>Options</div>
                            <span>Turn Timer Enabled</span> <input type="checkbox" checked={this.state.game?.turnTimerEnabled ?? false} onChange={this.toggleTurnTimer} disabled={userId !== this.state.game?.hostPlayerId}></input> 
                        </div>
                    }
                    {
                        this.state.gameOver && !this.state.bidCalled && !this.state.playerTimedOut &&
                        <div>
                            <h1>{this.state.winnerName} Won!</h1>
                            <button className="button btn-info" onClick={this.handlePlayAgain}>Play Again</button>
                            <button className="button btn-danger" onClick={this.leaveGame}>Leave Game</button>
                        </div>
                    }
                    {
                    game?.activePlayerId != null &&
                    <AnnouncementSystem 
                        messages={this.state.announcementMessages} 
                        onAllMessagesDisplayed={this.handleAllMessagesDisplayed}
                        numDice = {this.state.game?.players?.reduce((total, player) => total + player.dice.length, 0)}
                        palaficoRound = {this.state.game?.palaficoRound}
                    />
                    }
                </div>
            </div>
            <div className = {`chatBox ${this.state.mobileChatVisibile ? '' : 'chat-box-off'}`}>
                <div className="chat" tabIndex="0">
                    <div>
                        {
                            game?.chatHistory.map((message, index) => {
                                //find the player by username
                                const player = game?.players.find(player => player.username === message.playerName);

                                return (<span key={index}>
                                    <b style={{color : textColors[player?.colorIndex ?? 0]}}>{message.playerName}</b>: {message.text}
                                </span>)
                            })
                        }
                    </div>
                </div>
                <input
                        type="text"
                        value={this.state.chatMessage}
                        onKeyDown = {handleKeyPress}
                        placeholder = "Type here to chat..."
                        onChange={this.handleChangeChatMessage}
                    />
            </div>
            {
                !this.state.gameOver &&
                <Controls 
                    game={this.state.game}
                    userId={userId}
                    bid={this.state.bid}
                    bidCalled={this.state.bidCalled}
                    calzaCalled={this.state.calzaCalled}
                    decreaseBidNumber={this.decreaseBidNumber}
                    increaseBidNumber={this.increaseBidNumber}
                    makeBid={this.makeBid}
                    callCalza={this.callCalza}
                    callBid={this.callBid}
                    setBidFace={this.setBidFace}
                    isAnnouncementActive={this.state.isAnnouncementActive}
                    handlePlayerReady = {this.handlePlayerReady}
                    startGame = {this.startGame}
                    leaveGame = {this.leaveGame}
                />
            }
        </div>
        );
    }
}

export default Lobby;
