
import './App.css';
import LoginSignupBox from './login-page/LoginSignupBox.js'
import { useApp } from './AppContext.js';
import Navigation from './navigation/Navigation.js';


function App() {
  const { token } = useApp();
  return (
      <div className="App">
          {!token ?
          <LoginSignupBox/>
          :
          <Navigation></Navigation>
          }
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Mulish:wght@400;600&display=swap"></link>
      </div>
  );
}

export default App;
