
import AppContext from '../../AppContext';
import React, { Component } from 'react';

class Profile extends Component {
    static contextType = AppContext;
  
    constructor(props) {
        super(props);
        this.state = {
            stats: {},
            message: "",
        };
        this.apiUrl = process.env.REACT_APP_API_URL;
    }

    componentDidMount() {
        console.log("mounting");
        const { token } = this.context;

        // fetch the players stats
        fetch(`${this.apiUrl}/getStats`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${token}`,
            },
            })
            .then(response => {
                console.log(response);
                if (response.status === 403) {
                    this.context.logout();
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                this.setState({ stats: data.stats, message: data.message});
            });
    }
    
    decimalToPercentage(decimal) {
        return (decimal * 100).toFixed(1) + '%';
    }

    render()
    {
        //later,  display the game history as a row of cards, showing the games id and time played, clickable for replays
        return (
            <div className="feature-card login-form">
                <h1>Stats</h1>
                <h3>
                    {this.state.message}
                </h3>
                {this.state.stats &&
                <div className= "leaderboard-container">
                    <table className="leaderboard">
                        <thead>
                            <tr>
                                <th>Stat</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr className='leaderboard-row'>
                            <td>Winrate:</td>
                            <td>{this.state.stats.winrate === -1 ? "N/A" : this.decimalToPercentage(this.state.stats.winrate)}</td>
                        </tr>
                        <tr className='leaderboard-row'>
                            <td>Average Finish Above Last:</td>
                            <td>{this.state.stats.avg_place_offset === -1 ? "N/A" : parseFloat(this.state.stats.avg_place_offset).toFixed(2)}</td>
                        </tr>
                        <tr className='leaderboard-row'>
                            <td>Calza Success Rate:</td>
                            <td>{this.state.stats.calza_success_rate === -1 ? "N/A" : this.decimalToPercentage(this.state.stats.calza_success_rate)}</td>
                        </tr>
                        <tr className='leaderboard-row'>
                            <td>Calling Success Rate:</td>
                            <td>{this.state.stats.call_success_rate === -1 ? "N/A" : this.decimalToPercentage(this.state.stats.call_success_rate)}</td>
                        </tr>
                        <tr className='leaderboard-row'>
                            <td>Defending Success Rate:</td>
                            <td>{this.state.stats.defend_success_rate === -1 ? "N/A" : this.decimalToPercentage(this.state.stats.defend_success_rate)}</td>
                        </tr>
                        <tr className='leaderboard-row'>
                            <td>First Round Of Game Loss Rate:</td>
                            <td>{this.state.stats.first_die_lost_rate === -1 ? "N/A" : this.decimalToPercentage(this.state.stats.first_die_lost_rate)}</td>
                        </tr>
                        <tr className='leaderboard-row'>
                            <td>Percent Dice Lost To Palafico:</td>
                            <td>{this.state.stats.palafico_loss_rate === -1 ? "N/A" : this.decimalToPercentage(this.state.stats.palafico_loss_rate)}</td>
                        </tr>
                        <tr className='leaderboard-row'>
                            <td>Percent Of Your Own Palafico Rounds Lost: </td>
                            <td>{this.state.stats.self_palafico_loss_rate === -1 ? "N/A" : this.decimalToPercentage(this.state.stats.self_palafico_loss_rate)}</td>
                        </tr>
                        <tr className='leaderboard-row'>
                            <td>Called When First In Round:</td>
                            <td>{this.state.stats.called_when_first_rate === -1 ? "N/A" : this.decimalToPercentage(this.state.stats.called_when_first_rate)}</td>
                        </tr>
                        <tr className='leaderboard-row'>
                            <td>Overall Call Rate:</td>
                            <td>{this.state.stats.call_rate === -1 ? "N/A" : this.decimalToPercentage(this.state.stats.call_rate)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>}
            </div>
        );
        
    }
}

export default Profile;
