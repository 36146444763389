import React, { Component } from 'react';
import './HowToPlay.css';

class HowToPlay extends Component {
    render() {
        return (
            <div className='feature-card how-to-card'>
                <h1>How to Play Liars Dice</h1>
                <p>
                    <h3>Liars Dice is a dice game where players make bids on the number of dice with a certain face value that they believe are present in the combined dice rolls of all players. The objective is to make accurate bids and catch other players in lies.</h3>
                    <ol>
                        <li>The first player in the game to bid is chosen randomly.</li>
                        <li>The player makes a bid by stating a quantity of dice and a face value. For example, a player might bid "three 4s".</li>
                        <li>The next player must then either make a higher bid or challenge the previous bid.</li>
                        <ul>
                            <li>If a player challenges the previous bid, all players reveal their dice and the bid is checked for accuracy.</li>
                            <li>If the bid is accurate, the challenging player loses a die. If the bid is inaccurate, the player who made the bid loses a die.</li>
                        </ul>
                        <li>The game continues until only one player has dice remaining.</li>    
                    </ol>
                    <h3>
                        To join a game, add some friends, then join them through the friends page. You can create a game or view your current game by clicking the "Lobby" button.
                    </h3>
                </p> 
            </div>
        );
    }
}

export default HowToPlay;
