import React, { Component } from 'react';
import AppContext from '../../AppContext';
import './Friends.css';
import { FaCheck } from 'react-icons/fa';
import { FaCircleRight } from "react-icons/fa6";
import { FaCircleDot } from "react-icons/fa6";

class Friends extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            //a friend has id, username, onlineStatus, inGame, gameJoinCode, joinable, diceLeft, diceLeftInGame
            friends: [],
            //a friend request has a username and id
            friendRequests: [],
            //string to track the username of the friend to send a request to
            friendUsername: '',
            statusMessage: '',
            filteredFriends: [],
            filteredFriendRequests: [],
            friendSearchValue: '',
        };
        this.apiUrl = process.env.REACT_APP_API_URL;
    }

    componentDidMount() {
        const { socket } = this.context;
        this.fetchFriendsList();
        socket.on('friendOnline', userId => {
            console.log('friendOnline');
            console.log(userId);
            // Update the state to include the new friend data has the username and id
            const { friends } = this.state;
            const newFriends = friends.map(friend => {
                if (friend.id === userId) {
                    friend.onlineStatus = true;
                }
                return friend;
            });
            this.setState({ friends: newFriends });
        });

        socket.on('friendOffline', userId => {
            console.log('friendOffline');
            console.log(userId);
            // Update the state to include the new friend data has the username and id
            const { friends } = this.state;
            const newFriends = friends.map(friend => {
                if (friend.id === userId) {
                    friend.onlineStatus = false;
                }
                return friend;
            });
            this.setState({ friends: newFriends });
        });

        socket.on('friendInGame', data => {
            console.log('friendInGame');
            console.log(data);
            // Update the state to include the new friend
            const { friends } = this.state;
            const newFriends = friends.map(friend => {
                if (friend.id === data.userId) {
                    friend.inGame = true;
                    friend.gameJoinCode = data.gameJoinCode;
                    friend.joinable = data.joinable;
                    friend.diceLeft = data.diceLeft;
                    friend.diceLeftInGame = data.diceLeftInGame;
                }
                return friend;
            });
            this.setState({ friends: newFriends });            
        });

        socket.on('friendLeftGame', userId => {
            console.log('friendLeftGame');
            console.log(userId);
            // Update the state to include the new friend
            const { friends } = this.state;
            const newFriends = friends.map(friend => {
                if (friend.id === userId) {
                    friend.inGame = false;
                    friend.gameJoinCode = null;
                    friend.joinable = null;
                    friend.diceLeft = null;
                    friend.diceLeftInGame = null;
                }
                return friend;
            });
            this.setState({ friends: newFriends });            
        });

        socket.on('friendRequestSent', data => {
            console.log('friendRequestSent');
            console.log(data);
            // Update the state to include the new friend data has the username and id
            const { friendRequests } = this.state;
            friendRequests.push(data);
            this.setState({ friendRequests });
        });

        socket.on('friendRequestAccepted', data => {
            //log that we received the event
            console.log('friendRequestAccepted');
            console.log(data);
            // Update the state to include the new friend and remove the request
            const { friends, friendRequests } = this.state;
            //find the friend request using data.id
            friends.push({id: data.id, username: data.username, onlineStatus: data.onlineStatus, inGame: data.inGame, gameJoinCode: data.gameJoinCode, joinable: data.joinable, diceLeft: data.diceLeft, diceLeftInGame: data.diceLeftInGame});
            const newFriendRequests = friendRequests.filter(request => request.id !== data.id);
            this.setState({ friends, friendRequests: newFriendRequests });
        });
    }

    fetchFriendsList = () => {
        const { token } = this.context;
        fetch(`${this.apiUrl}/getFriendsList`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            //log everything
            console.log(data);
            this.setState({ friends: data.friends, friendRequests: data.friendRequests });
        })
        .catch(error => 
            {
                // if it was 403, logout
                if (error.response.status === 403) {
                    this.context.logout();
                }
                console.error('Error:', error);
            });
    };

    filterFriendsList = (username) => {
        this.setState({ friendSearchValue: username });
        //filter the friends list on the client side
        const { friends } = this.state;
        const filteredFriends = friends.filter(friend => friend.username.toLowerCase().includes(username.toLowerCase()));
        this.setState({ filteredFriends: filteredFriends });

        //filter the friend requests too
        const { friendRequests } = this.state;
        const filteredFriendRequests = friendRequests.filter(request => request.username.toLowerCase().includes(username.toLowerCase()));
        this.setState({ filteredFriendRequests: filteredFriendRequests });

        //clear if the search is empty
        if (username === '') {
            this.setState({ filteredFriends: [], filteredFriendRequests: [] });
        }

    }

    sendFriendRequest = () => {
        const { token } = this.context;
        const friendUsername = this.state.friendUsername;
    
        fetch(`${this.apiUrl}/sendFriendRequest`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ friendUsername })
        })
        .then(response => {
            if (response.status === 400) {
                // If the status code is 400, parse the JSON to get the error message
                return response.json().then(data => {
                    throw new Error(data.error); // Throw an error with the message from the response
                });
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            this.setState({ friendUsername: '' });
            this.setState({ statusMessage: "Friend request sent!" });
        })
        .catch(error => {
            console.error('Error:', error);
            this.setState({ statusMessage: error.message }); // Update statusMessage with the error message
        });
    };
    

    acceptFriendRequest = friendId => {
        // post to acceptFriendRequest, with the friendId
        const { token } = this.context;
        fetch(`${this.apiUrl}/acceptFriendRequest`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ friendId })
        })
        .then(response => response.json())
        .then(data => {
            //log everything
            console.log(data);
        })
        .catch(error => console.error('Error:', error));
    };

    handleJoin = async (joinCode) => {
        try {
            const { token, joinGame } = this.context;

            const response = await fetch(`${this.apiUrl}/joinGame/${joinCode}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${token}`,
                },
            });

            const data = await response.json();

            if (!response.ok) {
                //if error code is 400, user is already in game
                if (response.status === 400) {
                    this.setState({ statusMessage: 'You are already in a game.' });
                    return;
                }
                //if its 406 the game is full
                if (response.status === 406) {
                    this.setState({ statusMessage: 'The game is full' });
                    return;
                }
                //if its 409 the game is already started
                if (response.status === 409) {
                    this.setState({ statusMessage: 'The game has already started' });
                    return;
                }

                throw new Error(data.error || 'Error joining game.');
            }
            
            joinGame(data.game.joinCode);

            // Update the UI to navigate to the Lobby
            this.props.updateSelectedMenuItem('Lobby');
        } catch (error) {
            console.error('Error joining game:', error.message);
            this.setState({ errorText: 'Error joining game. Please try again.' });
        }
    };

    renderFriendsList = () => {
        let {friends, friendRequests} = this.state;

        //use filtered friends instead if the search bar has a value
        if (this.state.filteredFriendRequests.length > 0 || this.state.filteredFriends.length > 0) {
            friends = this.state.filteredFriends;
            friendRequests = this.state.filteredFriendRequests;
        }

        // Combine friends and friend requests
        const combinedList = [
            ...friends, // Existing friends
            ...friendRequests.map(request => ({ ...request, isRequest: true })) // Friend requests with an additional flag
        ];

        //first show all friend requests, then show all online users, then all non-online users, alphabetically sortted within the two
        combinedList.sort((a, b) => {
            if (a.isRequest && !b.isRequest) {
                return -1;
            }
            if (!a.isRequest && b.isRequest) {
                return 1;
            }
            if (a.isRequest && b.isRequest) {
                return a.username.localeCompare(b.username);
            }
            if (a.onlineStatus && !b.onlineStatus) {
                return -1;
            }
            if (!a.onlineStatus && b.onlineStatus) {
                return 1;
            }
            return a.username.localeCompare(b.username);
        });
        
        console.log(combinedList);
        return (
            <div className="friends-list-container">
                {combinedList.map(item => (
                    <div className={`inverse-feature-card friend-card ${item.isRequest ? 'request': ''}`} key={item.id}>
                        <span>{item.username}{!item.isRequest && <FaCircleDot className='online-circle' style = {{color: item.onlineStatus ? 'green' : 'red'}}/>}</span>
                        {!item.isRequest && item.inGame && !item.joinable && <div className='in-game'>In Game - {item.diceLeft} dice --- {item.diceLeftInGame} dice left in game </div>}
                        {item.isRequest ? (
                            <div >
                                <span id="accept-text">Accept?: </span>
                                <button className="btn-check" onClick={() => this.acceptFriendRequest(item.id)}>
                                    <FaCheck className = "accept-friend-button" />
                                </button>
                            </div>
                        ) : (
                            <div className = "actions">
                                {
                                    item.inGame && item.joinable &&
                                    <button className="btn-join" onClick={() => this.handleJoin(item.gameJoinCode)}>
                                        <FaCircleRight alt="Join Game" className = "join-game-button"/>
                                    </button>
                                }
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    };

    render() {
        const handleKeyPressSend = (e) => {
            if (e.key === 'Enter') {
                this.sendFriendRequest();
            }
        };

        //get whether we have any friends or requests, or filterd friends or requests
        let {friends, friendRequests} = this.state;
        if (this.state.filteredFriendRequests.length > 0 || this.state.filteredFriends.length > 0) {
            friends = this.state.filteredFriends;
            friendRequests = this.state.filteredFriendRequests;
        }

        const friendsCount = friends.length;
        const friendRequestsCount = friendRequests.length;
        const totalCount = friendsCount + friendRequestsCount;


        return (
            <div className="feature-card friends-div">
                <div className="friends-header">Friends</div>
                { totalCount > 0 && <input
                    className='form-field friends-search'
                    type="text"
                    value={this.state.friendSearchValue}
                    onChange={(e) => this.filterFriendsList( e.target.value)}
                    placeholder="Filter friends"
                />
                }
                {
                    totalCount === 0 && <div className="no-friends">You have no friends or requests. Send some requests!</div>
                }
                {this.renderFriendsList()}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent:'center', width: '100%' }}>
                    <div className="form-group" id="send-request-group">
                        <span>Send Request</span>
                        <input
                            className="form-field"
                            id="friends-input"
                            type="text"
                            value={this.state.friendUsername}
                            onChange={(e) => this.setState({ friendUsername: e.target.value })}
                            placeholder="Enter username"
                            onKeyDown = {handleKeyPressSend}
                        />
                    </div>
                    <button 
                        className="button btn-green friends-button" 
                        onClick={() => this.sendFriendRequest()}
                        style={{ marginLeft: '10px' }}
                    >
                        Send
                    </button>
                </div>
                <div>{this.state.statusMessage}</div>
            </div>
        );
    }
}

export default Friends;
