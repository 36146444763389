import Die from "../gameplay/Die.js";
import React, { Component } from 'react';
import HostCrown from '../hostCrown.svg';
import {VscTriangleRight, VscTriangleLeft} from 'react-icons/vsc';
import { GiBootKick } from "react-icons/gi";
import { diceColors } from "../DiceImages/diceColors.js";

class PlayerCard extends Component {
    constructor(props) {
        super(props);
        this.diceRefs = props.dice.map(() => React.createRef());
        this.cupRef = React.createRef();
        this.state = {
            dice: props.dice,
            calzaSuccess: false,
        };
        this.victorySound = new Audio('/sounds/victory.mp3');
        this.timerWarningSound = new Audio('/sounds/timerWarning.mp3');
    }
    adjustDieSize = () => {
        this.diceRefs.forEach(ref => {
          const dieElement = ref.current;
          if (dieElement) {
            const width = dieElement.offsetWidth;
            dieElement.style.height = `${width}px`;
          }
        });
    };

    adjustCupSize = () =>
    {
        const cupElement = this.cupRef.current;
        if (cupElement) {
            const width = cupElement.offsetWidth;
            //calculate the height using the cup aspect ratio
            const height = width * 1.24;
            cupElement.style.height = `${height}px`;
        }
    }

    componentDidMount() {
        this.adjustDieSize();
        this.adjustCupSize();
        window.addEventListener('resize', this.adjustDieSize);
        window.addEventListener('resize', this.adjustCupSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.adjustDieSize);
        window.removeEventListener('resize', this.adjustCupSize);
    }
    
    componentDidUpdate(prevProps) {
        this.adjustDieSize();

        if (this.props.dice !== prevProps.dice) {
            this.setState({ dice: this.props.dice });
        }
        // Check if the resetDieAnimation prop changed
        if (this.props.resetDieAnimation && !prevProps.resetDieAnimation) {
            this.resetDieAnimation();
        }

        // Check if the loser prop changed
        if (this.props.loser && !prevProps.loser) {
            setTimeout(() => this.calculateAndAnimateLosingDie(this.diceRefs[0].current), 3000);
        }

        //check if the calzaSuccessprop changed
        if (this.props.calzaSuccess && !prevProps.calzaSuccess) {
            //create a new die that begins transparent and fades in
            if (this.props.calzaSuccess && !prevProps.calzaSuccess) {
                setTimeout(() => {
                    const newDice = [...this.props.dice, 6];
                    this.setState({ dice: newDice, calzaSuccess: true });
                    // Add a new ref for the new die
                    this.diceRefs.push(React.createRef());
                }, 3000);
            }                
        }
        if (!this.props.calzaSuccess && prevProps.calzaSuccess) {
            this.setState({calzaSuccess: false});
        }

        // Check if the winner prop changed
        if (this.props.winner && !prevProps.winner) {
            this.victorySound.play();
        }

        //check if the turn timer has hit 5 seconds and the player is both the active player and the current player
        if (this.props.turnTimer === 5 && this.props.active && this.props.isCurrentPlayer) {
            //play the timer sound
            this.timerWarningSound.play();
        }
    }

    calculateAndAnimateLosingDie = (dieElement) => {
        if (!dieElement) return;
    
        dieElement.style.transition = 'transform 2s, opacity 1s';
        dieElement.style.opacity = '0';
        dieElement.classList.remove('active');
    
        const dieRect = dieElement.getBoundingClientRect();
        const centerX = window.innerWidth / 2 - dieRect.width / 2;
        const centerY = window.innerHeight / 2 - dieRect.height / 2;
        const translateX = centerX - dieRect.left;
        const translateY = centerY - dieRect.top;
    
        dieElement.style.transform = `translate(${translateX}px, ${translateY}px) scale(0)`;
    };

    resetDieAnimation = () => {
        this.diceRefs.forEach(ref => {
            const dieElement = ref.current;
            if (dieElement) {
                dieElement.style.transition = '';
                dieElement.style.transform = '';
                dieElement.style.opacity = '';
                dieElement.style.scale = '';
            }
        });
    };

    render()
    {
        const MAX_TURN_TIME = 30;
        const diceCount = this.state.dice.length;
        const dieWidth = 2; // Fixed width of a die (vw)
        const containerWidthForTwo = (dieWidth * 2) + 2; // Calculate width for 2 dice per row
        const containerWidthForThree = (dieWidth * 3) + 2.5; // Calculate width for 3 dice per row
        const baseLeft = 45;
        const adjustedLeft = 57;
        // Calculate the width percentage of the timer bar based on the turn timer
        const timerWidthPercent = (this.props.turnTimer / MAX_TURN_TIME) * 100;

        const timerBarStyle = {
            width: `${timerWidthPercent}%`,
            transition: this.props.isTimerResetting ? 'none' : 'width 1s linear',
            backgroundColor: this.props.turnTimer <= 5 ? 'red' : 'green',
            border: this.props.turnTimer <= 5 ? '1px solid red' : '1px solid green',
        };
        // Calculate the width percentage of the bank time bar based on the bank time
        const timeBankWidthPercent = (this.props.timeBank / MAX_TURN_TIME) * 100;

        const timeBankBarStyle = {
            width: `${timeBankWidthPercent}%`,
            transition: this.props.isTimerResetting ? 'none' : 'width 1s linear',
            backgroundColor: 'blue',
            border: '1px solid blue',
        };

        const cupImage = `<?xml version="1.0" standalone="no"?>
        <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
         "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 116.000000 143.000000"
         preserveAspectRatio="xMidYMid meet">
        <metadata>
        Created by potrace 1.10, written by Peter Selinger 2001-2011
        </metadata>
        <g transform="translate(0.000000,143.000000) scale(0.100000,-0.100000)"
        fill="${diceColors[this.props.colorIndex]}" stroke="none">
        <path d="M330 1410 c-122 -15 -140 -31 -165 -149 -8 -41 -49 -320 -90 -620
        l-76 -545 23 -18 c61 -48 191 -62 548 -63 332 0 455 11 530 48 34 17 46 28 48
        49 3 23 -78 645 -141 1087 l-22 155 -32 19 c-56 32 -173 46 -373 45 -102 0
        -214 -4 -250 -8z"/>
        </g>
        </svg>`;

        const containerStyle = {
            width: ((diceCount === 3 || diceCount === 4) && !this.state.dice.every(die => die === 0)) ? `${containerWidthForTwo}vmax` : `${containerWidthForThree}vmax`,
            left: ((diceCount === 3 || diceCount === 4) && !this.state.dice.every(die => die === 0))  ? `${adjustedLeft}%` : `${baseLeft}%`,
        };
        const divClass = `${this.props.active === true ? 'active' : ''} ${this.props.ready === true ? 'ready' : ''} ${this.props.villain === true ? 'villain' : ''} ${this.props.winner === true ? 'winner' : ''} ${this.props.loser === true ? 'loser' : ''} ${this.props.calzaSuccess === true ? 'calza-winner' : ''} ${this.props.dice.length === 0 ? 'inactive' : ''} `;
        return(
            <div ref={this.props.forwardedRef} className = {`inverse-feature-card player-card ${divClass}`} id ={"playerCard" + this.props.position}>
                { this.props.hosting === true && !this.props.started ? <img className="host-crown" src={HostCrown} alt="host-marker"></img> : null}
                <div className = "player-info">
                    <div className = "player-name">{this.props.userName}</div>
                    {this.props.lastBid[1] !== 0 &&
                        <div className={`lastBid ${this.props.villain === true ? 'villain' : ''}`}>
                            <span className="bidNumber">{this.props.lastBid[0]}</span>
                            <span></span>
                            <Die style={{ transform: 'translateX(2px)' }} fillColor={diceColors[this.props.colorIndex]} dieValue={this.props.lastBid[1]} />
                        </div>
                    }
                </div>
                
                {this.state.dice && this.state.dice.length > 0  && this.state.dice.some(die => die !== 0) && 
                    <div className = "player-dice" style={containerStyle}>    
                        {this.state.dice.map((die, index) => {
                            return <Die ref={ this.diceRefs[index] } fillColor={diceColors[this.props.colorIndex]} key={index} newDie={this.state.calzaSuccess && index === this.state.dice.length-1} dieValue={die} active={(!this.props.palafico && die === 1) || die === this.props.gameBid[1]} bidCalled={this.props.bidCalled || this.props.calzaCalled}/>
                        })}
                    </div>
                }
                
                { this.state.dice.length>0 && this.state.dice.every(die => die === 0) &&
                    <div className="cup-div">
                        {this.props.isCurrentPlayer && <button className="color-arrow" style={{left: "0%"}} onClick={this.props.changeColorLeft}> <VscTriangleLeft className = "color-arrow-icon"/> </button>}
                            <div
                                ref = {this.cupRef}
                                className="cup" 
                                dangerouslySetInnerHTML={{ __html: cupImage }} 
                                aria-label={`cup with ${this.state.dice.length} dice`}
                            /> 
                        <div className="cup-text">{this.state.dice.length}</div>
                        {this.props.isCurrentPlayer && <button className="color-arrow" style={{right: "0%"}} onClick={this.props.changeColorRight}> <VscTriangleRight className = "color-arrow-icon"/> </button>}
                    </div>
                }
                {
                this.props.turnTimerEnabled &&  this.props.started && this.props.active && !this.props.bidCalled && !this.props.calzaCalled && !this.props.playerTimedOut &&
                <div className="timer-bar-container">
                    <div className="timer-bar" style={timerBarStyle}></div>
                    <div className="bank-time-bar" style={timeBankBarStyle}></div>
                </div>
                }

                {this.props.currentIsHost && !this.props.isCurrentPlayer && !this.props.gameStarted && <button className = 'btn-kick' onClick={this.props.kickPlayer}> <GiBootKick style={{ color: "#ad0d02"}} className = "color-arrow-icon" /> </button>}
            </div>
        )
    }
}

export default PlayerCard;