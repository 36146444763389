import React from 'react';

const Die = React.forwardRef((props, ref) => {
    const { dieValue, active, bidCalled, extraClass, fillColor, newDie, centerCounterRef, onMouseMove, ...rest} = props;
    const Die0 = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300.000000 300.000000"
        preserveAspectRatio="xMidYMid meet">
        <metadata>
        Created by potrace 1.10, written by Peter Selinger 2001-2011
        </metadata>
        <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
        fill="${fillColor}" stroke="none">
        <path d="M380 2991 c-105 -5 -133 -10 -175 -31 -68 -33 -134 -102 -173 -179
        l-32 -64 0 -1217 0 -1217 32 -64 c41 -81 106 -146 187 -187 l64 -32 1217 0
        1217 0 64 32 c81 41 146 106 187 187 l32 64 0 1217 0 1217 -32 64 c-41 81
        -106 146 -187 187 l-64 32 -1106 -2 c-608 -1 -1162 -4 -1231 -7z m1269 -715
        c91 -15 241 -87 287 -138 121 -132 140 -350 45 -508 -23 -38 -93 -110 -103
        -104 -4 3 -8 1 -8 -4 0 -6 -32 -34 -70 -62 -39 -29 -81 -67 -94 -84 -27 -37
        -46 -99 -46 -150 l0 -36 -141 0 -142 0 8 72 c12 130 16 151 30 179 19 35 109
        126 185 184 82 63 116 115 119 185 2 30 5 58 7 62 2 3 0 9 -6 13 -6 4 -8 10
        -5 14 2 5 -8 28 -24 53 -39 62 -93 86 -183 83 -96 -4 -140 -31 -178 -110 -16
        -33 -34 -89 -39 -125 -9 -55 -16 -70 -45 -97 -65 -60 -161 -52 -219 19 -22 28
        -29 46 -29 79 0 72 2 82 19 139 9 30 16 58 15 61 -1 3 18 37 41 75 62 101 154
        165 282 199 49 12 222 13 294 1z m-22 -1207 c74 -46 109 -154 76 -238 -23 -59
        -96 -119 -137 -112 -4 1 -14 -3 -21 -9 -10 -8 -19 -8 -35 0 -12 6 -24 9 -27 6
        -3 -3 -19 2 -36 11 -84 43 -125 129 -107 220 6 29 16 53 21 53 5 0 9 5 9 11 0
        15 60 67 84 72 63 13 140 6 173 -14z"/>
        </g>
        </svg>`;
    const Die1 = `<?xml version="1.0" standalone="no"?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
     "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300.000000 300.000000"
     preserveAspectRatio="xMidYMid meet">
    <metadata>
    Created by potrace 1.10, written by Peter Selinger 2001-2011
    </metadata>
    <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
    fill="${fillColor}" stroke="none">
    <path d="M269 2986 c-103 -28 -190 -103 -238 -205 l-26 -56 0 -1225 0 -1225
    27 -57 c37 -79 98 -142 177 -181 l66 -32 1225 0 1225 0 66 32 c79 39 140 102
    177 181 l27 57 0 1225 0 1225 -32 66 c-39 79 -102 140 -181 177 l-57 27 -1205
    2 c-934 1 -1215 -1 -1251 -11z m1384 -1144 c65 -29 151 -113 184 -181 25 -50
    28 -67 28 -161 0 -95 -3 -110 -28 -160 -37 -72 -105 -141 -176 -176 -51 -26
    -65 -29 -161 -29 -94 0 -111 3 -161 28 -68 33 -152 119 -181 184 -32 71 -37
    210 -10 281 38 99 121 184 219 223 67 27 217 22 286 -9z"/>
    </g>
    </svg>
    `;
    const Die2 = `<?xml version="1.0" standalone="no"?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
     "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300.000000 300.000000"
     preserveAspectRatio="xMidYMid meet">
    <metadata>
    Created by potrace 1.10, written by Peter Selinger 2001-2011
    </metadata>
    <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
    fill="${fillColor}" stroke="none">
    <path d="M269 2986 c-103 -28 -190 -103 -238 -205 l-26 -56 0 -1225 0 -1225
    27 -57 c37 -79 98 -142 177 -181 l66 -32 1185 -3 c855 -2 1202 0 1245 8 120
    23 222 106 267 218 l23 57 0 1220 0 1220 -32 66 c-39 79 -102 140 -181 177
    l-57 27 -1205 2 c-934 1 -1215 -1 -1251 -11z m602 -359 c281 -137 281 -537 -1
    -675 -49 -24 -66 -27 -160 -27 -87 1 -113 5 -153 23 -66 30 -148 110 -183 179
    -27 53 -29 67 -29 163 0 95 3 110 28 160 47 92 123 160 218 194 71 26 210 17
    280 -17z m1557 -1569 c71 -26 163 -112 199 -186 25 -51 28 -67 28 -162 0 -100
    -1 -108 -34 -168 -41 -75 -103 -135 -179 -170 -50 -24 -69 -27 -157 -27 -92 0
    -105 3 -160 32 -70 36 -148 115 -176 177 -42 97 -40 227 6 321 85 174 286 252
    473 183z"/>
    </g>
    </svg>
    `;
    const Die3 = `<?xml version="1.0" standalone="no"?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
     "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300.000000 300.000000"
     preserveAspectRatio="xMidYMid meet">
    <metadata>
    Created by potrace 1.10, written by Peter Selinger 2001-2011
    </metadata>
    <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
    fill="${fillColor}" stroke="none">
    <path d="M263 2986 c-103 -33 -185 -106 -232 -205 l-26 -56 0 -1225 0 -1225
    27 -57 c37 -79 98 -142 177 -181 l66 -32 1220 0 1220 0 57 23 c81 33 158 106
    194 184 l29 63 0 1225 0 1225 -32 66 c-39 79 -102 140 -181 177 l-57 27 -1210
    2 c-982 1 -1218 -1 -1252 -11z m609 -359 c76 -37 160 -128 188 -204 29 -77 29
    -188 2 -261 -41 -106 -137 -199 -240 -228 -73 -22 -199 -15 -265 14 -65 29
    -148 110 -183 179 -27 53 -29 67 -29 163 0 95 3 110 28 160 47 92 123 160 218
    194 71 26 210 18 281 -17z m763 -778 c74 -27 165 -112 202 -187 25 -51 28 -67
    28 -162 0 -95 -3 -110 -28 -160 -37 -72 -105 -141 -176 -176 -51 -26 -65 -29
    -161 -29 -96 0 -110 2 -163 29 -68 35 -150 118 -180 183 -18 40 -22 66 -22
    153 0 95 3 111 28 162 36 73 128 159 199 187 74 28 198 28 273 0z m793 -791
    c71 -26 163 -112 199 -186 25 -51 28 -68 28 -157 0 -93 -2 -105 -32 -166 -39
    -79 -102 -140 -181 -177 -50 -24 -70 -27 -157 -27 -91 0 -105 3 -158 30 -78
    39 -159 127 -187 202 -27 71 -29 187 -6 253 74 204 289 303 494 228z"/>
    </g>
    </svg>
    `;
    const Die4 = `<?xml version="1.0" standalone="no"?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
     "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300.000000 300.000000"
     preserveAspectRatio="xMidYMid meet">
    <metadata>
    Created by potrace 1.10, written by Peter Selinger 2001-2011
    </metadata>
    <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
    fill="${fillColor}" stroke="none">
    <path d="M269 2986 c-103 -28 -190 -103 -238 -205 l-26 -56 0 -1225 0 -1225
    27 -57 c37 -79 98 -142 177 -181 l66 -32 1225 0 1225 0 66 32 c79 39 140 102
    177 181 l27 57 0 1220 0 1220 -23 57 c-32 80 -99 150 -180 190 l-67 33 -1205
    2 c-934 1 -1215 -1 -1251 -11z m604 -361 c78 -38 158 -126 187 -202 45 -119
    25 -255 -54 -361 -94 -128 -276 -180 -429 -122 -77 29 -167 113 -203 188 -39
    81 -45 203 -14 285 39 105 111 179 216 223 51 21 71 24 149 21 75 -3 100 -8
    148 -32z m1575 1 c75 -38 133 -95 173 -168 33 -60 34 -68 34 -168 0 -96 -2
    -110 -30 -163 -39 -78 -127 -159 -202 -187 -71 -26 -186 -29 -253 -5 -69 24
    -145 81 -184 138 -50 73 -67 129 -67 217 -1 97 27 172 87 239 92 103 164 133
    299 128 74 -3 99 -8 143 -31z m-1577 -1579 c282 -138 282 -536 0 -675 -50 -24
    -67 -27 -161 -27 -95 0 -110 3 -160 28 -73 38 -135 99 -173 172 -30 56 -32 66
    -32 165 0 99 2 109 32 165 47 89 122 156 214 189 71 26 210 18 280 -17z m1579
    -1 c72 -36 141 -104 176 -175 26 -51 29 -65 29 -161 0 -95 -3 -110 -28 -160
    -37 -73 -106 -141 -176 -176 -114 -56 -276 -43 -377 32 -104 77 -156 178 -156
    304 0 159 98 299 245 352 80 28 214 21 287 -16z"/>
    </g>
    </svg>`;
    const Die5 = `<?xml version="1.0" standalone="no"?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
     "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300.000000 300.000000"
     preserveAspectRatio="xMidYMid meet">
    <metadata>
    Created by potrace 1.10, written by Peter Selinger 2001-2011
    </metadata>
    <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
    fill="${fillColor}" stroke="none">
    <path d="M274 2986 c-109 -27 -204 -109 -246 -215 l-23 -56 -3 -1180 c-3
    -1233 -2 -1256 40 -1339 33 -64 103 -131 171 -162 l62 -29 1225 0 1225 0 66
    32 c79 39 140 102 177 181 l27 57 0 1225 0 1225 -32 66 c-39 79 -102 140 -181
    177 l-57 27 -1200 2 c-932 1 -1211 -1 -1251 -11z m599 -361 c78 -38 158 -126
    187 -202 26 -71 29 -186 5 -253 -37 -105 -130 -198 -235 -235 -67 -24 -182
    -21 -253 5 -76 29 -167 112 -204 188 -25 51 -28 67 -28 162 0 96 3 110 28 158
    77 146 193 215 352 209 75 -3 100 -8 148 -32z m1575 1 c75 -38 133 -95 173
    -168 33 -60 34 -68 34 -168 0 -95 -3 -111 -28 -162 -37 -76 -128 -159 -204
    -188 -71 -26 -186 -29 -253 -5 -106 37 -198 130 -235 235 -23 66 -21 184 5
    253 36 95 129 184 231 221 71 26 210 17 277 -18z m-786 -789 c66 -32 151 -118
    181 -184 32 -69 32 -237 0 -306 -31 -66 -115 -152 -182 -184 -50 -25 -67 -28
    -161 -28 -87 0 -113 4 -153 22 -66 31 -152 115 -184 182 -25 50 -28 67 -28
    161 0 95 3 110 28 160 47 92 123 160 218 194 71 26 210 18 281 -17z m-791
    -790 c281 -137 281 -537 -1 -675 -49 -24 -66 -27 -160 -27 -96 0 -110 3 -161
    29 -71 35 -139 104 -176 176 -25 50 -28 65 -28 160 0 95 3 110 28 160 47 92
    123 160 218 194 71 26 210 17 280 -17z m1579 -1 c72 -36 141 -104 176 -175 26
    -51 29 -65 29 -161 0 -95 -3 -110 -28 -160 -37 -73 -106 -141 -176 -176 -114
    -56 -276 -43 -377 32 -104 77 -156 178 -156 304 0 100 28 173 93 245 91 98
    158 126 294 122 75 -3 99 -8 145 -31z"/>
    </g>
    </svg>`;
    const Die6 = `<?xml version="1.0" standalone="no"?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
     "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300.000000 300.000000"
     preserveAspectRatio="xMidYMid meet">
    <metadata>
    Created by potrace 1.10, written by Peter Selinger 2001-2011
    </metadata>
    <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
    fill="${fillColor}" stroke="none">
    <path d="M269 2986 c-103 -28 -190 -103 -238 -205 l-26 -56 0 -1225 0 -1225
    27 -57 c37 -79 98 -142 177 -181 l66 -32 1225 0 1225 0 62 29 c76 35 144 104
    181 184 l27 57 0 1225 0 1225 -32 66 c-39 79 -102 140 -181 177 l-57 27 -1205
    2 c-934 1 -1215 -1 -1251 -11z m604 -361 c78 -38 158 -126 187 -202 26 -70 29
    -187 6 -251 -73 -205 -325 -298 -533 -197 -64 32 -126 93 -161 160 -24 45 -27
    61 -27 155 0 96 3 110 28 158 77 146 193 215 352 209 75 -3 100 -8 148 -32z
    m1569 3 c31 -14 77 -45 102 -68 106 -98 146 -268 94 -398 -59 -145 -183 -225
    -348 -225 -174 0 -312 95 -360 248 -20 62 -15 171 10 237 21 58 86 140 141
    179 92 65 253 78 361 27z m-1598 -798 c249 -85 324 -393 140 -577 -180 -180
    -500 -130 -611 95 -25 50 -28 68 -28 152 0 112 18 161 86 235 71 77 159 113
    280 114 51 1 95 -5 133 -19z m1614 -14 c80 -37 152 -114 182 -194 30 -79 25
    -194 -11 -272 -69 -146 -241 -232 -405 -201 -294 54 -410 381 -210 589 77 81
    159 112 286 109 80 -2 105 -7 158 -31z m-1637 -762 c162 -47 264 -182 262
    -349 -2 -191 -145 -340 -339 -353 -239 -15 -417 154 -402 383 15 232 247 387
    479 319z m1633 -22 c76 -36 135 -91 173 -164 25 -48 28 -62 28 -158 0 -96 -3
    -110 -28 -158 -140 -266 -537 -266 -673 0 -32 61 -44 185 -25 253 58 215 313
    326 525 227z"/>
    </g>
    </svg>`;

    const diceImages = [Die0, Die1, Die2, Die3, Die4, Die5, Die6];
    const dieImage = diceImages[dieValue];

     // When the mouse moves over Die, call the passed onMouseMove
  const handleMouseMove = (event) => {
      if (onMouseMove) {
          onMouseMove(event, centerCounterRef);
      }
  };

    return (
        <div 
            ref={ref} 
            className={`die ${bidCalled && !newDie ? (active ? 'active' : 'inactive') : ''} ${newDie ? 'newDie' : ''} ${extraClass ? extraClass : ''}`}
            dangerouslySetInnerHTML={{ __html: dieImage }} 
            aria-label={`Die with value ${dieValue}`}
            onMouseMove={handleMouseMove}
            {...rest}
        />
    );
});

export default Die;
