import React, { createContext, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';
import io from 'socket.io-client';

const AppContext = createContext();

export class AppProvider extends React.Component {
  constructor(props) {
    super(props);

    // Retrieve the token from localStorage
    const token = localStorage.getItem('token');
    let userId = null;
    let username = null;
    //retrieve the gameid from localstorage
    const gameId = localStorage.getItem('gameId');

    if (token) {
      try {
        const decoded = jwtDecode(token);
        userId = decoded.id; // Assuming the decoded token contains the user ID as 'id'
        username = decoded.username; // Assuming the decoded token contains the username as 'username'
      } catch (error) {
        console.error('Error decoding token:', error);
        // Handle the error (e.g., token is invalid or expired)
        localStorage.removeItem('token');
        localStorage.removeItem('gameId');
      }
    }
  
    this.state = {
      token: token,
      userId: userId,
      username: username,
      gameId: gameId,
      socket: null,
    };

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.joinGame = this.joinGame.bind(this);
    this.leaveGame = this.leaveGame.bind(this);
    this.apiUrl = process.env.REACT_APP_WEBSOCKET_URL; 
  }

  componentDidMount() {
    const { token, userId } = this.state;
    if (token) {
      this.initializeSocketConnection(token, userId);
    }
    this.setupActivityTracker();
    this.setupTokenRefresh();
  }

  setupActivityTracker = () => {
    const inactivityTime = 5 * 60 * 1000; // 5 minutes
    let activityTimer = setTimeout(this.logout, inactivityTime);

    const resetTimer = () => {
      clearTimeout(activityTimer);
      activityTimer = setTimeout(this.logout, inactivityTime);
    };

    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('keypress', resetTimer);
  };

  setupTokenRefresh = () => {
    const refreshInterval = 25 * 60 * 1000; // 25 minutes
    setInterval(this.refreshToken, refreshInterval);
  };

  refreshToken = async () => {
    if (!this.state.token) return;
    try {
      const response = await fetch('/api/refreshToken', {
        method: 'POST',
        headers: {
          'Authorization': this.state.token,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.login(data.token, data.gameId);
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { token, userId } = this.state;
    if (token && prevState.token === null) {
      this.initializeSocketConnection(token, userId);
    }
  }

  initializeSocketConnection = (token, userId) => {
    const socket = io(this.apiUrl, {
      extraHeaders: {
        Authorization: `${token}`,
      },
      path: '/socket.io',
      transports: ['websocket']
    });
    
    // Emit the 'register' event once the socket is connected
    socket.on('connect', () => {
      console.log('Socket connected:', socket.id);
      socket.emit('registerSocket', userId);
    });

    socket.on('clearOldSocket', () => {
      console.log('Clearing old socket');
      socket.disconnect();
      this.logout();
    });

    this.setState({ socket });

  };

  login(newToken, gameId) {
    const decoded = jwtDecode(newToken);
    this.setState({ 
      token: newToken,
      userId: decoded.id, 
      username: decoded.username
    });
    // Store the token in localStorage
    localStorage.setItem('token', newToken);
    if (gameId) {
      this.joinGame(gameId);
    }
  }

  logout() {
    //disconnect the socket
    const { socket } = this.state;
    if (socket)
    {
      socket.disconnect();
    }

    this.setState({ token: null, userId: null, username: null, socket: null, gameId: null });
    // Clear the token from localStorage
    localStorage.removeItem('token');
    // Clear the gameId from localStorage
    localStorage.removeItem('gameId');
  }

  joinGame = (id, callback) => {
    this.setState({ gameId: id }, () => {
      console.log("Updated gameId in context:", this.state.gameId);
      // Store the gameId in localStorage
      localStorage.setItem('gameId', id);
      if (callback) callback();
    });
  };

  leaveGame = () => {
    this.setState({ gameId: null });
    // Remove the gameId from localStorage
    localStorage.removeItem('gameId');
  };

  render() {
    const { children } = this.props;
    const { token, userId, username, gameId, socket } = this.state;

    return (
      <AppContext.Provider value={{
        token,
        userId,
        username,
        gameId,
        socket,
        login: this.login,
        logout: this.logout,
        joinGame: this.joinGame,
        leaveGame: this.leaveGame,
        // You can also expose socket-related methods if needed
      }}>
        {children}
      </AppContext.Provider>
    );
  }
}

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
};

export default AppContext;
