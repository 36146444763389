
import AppContext from '../AppContext';
import React, { Component } from 'react';

class Home extends Component {
    static contextType = AppContext;
  
    constructor(props) {
        super(props);
        this.state = {
            games: [],
            rating: 0,
            leaderboard: [],
        };
        this.apiUrl = process.env.REACT_APP_API_URL;
    }

    componentDidMount() {
        console.log("mounting");
        const { token } = this.context;

        /*fetch(`${this.apiUrl}/getGameHistoryForPlayer`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
        },
        })
        .then(response => {
            console.log(response);
            if (response.status === 403) {
                this.context.logout();
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            this.setState({ games: data.games});
        });*/

        fetch(`${this.apiUrl}/getPlayerRating`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${token}`,
            },
            })
            .then(response => {
                console.log(response);
                if (response.status === 403) {
                    this.context.logout();
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                //multiply mu value by 40 to get the display rating, 
                this.setState({ rating: data.rating * 40});
            });

        // fetch the leaderboard
        fetch(`${this.apiUrl}/getLeaderboard`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${token}`,
            },
            })
            .then(response => {
                console.log(response);
                if (response.status === 403) {
                    this.context.logout();
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                this.setState({ leaderboard: data.leaderboard});
            });
    
    }

    

    render()
    {

        function interpolateColor(rating) {
            const MIN_RATING = 500;
            const MID_RATING = 1000; // Mid-point of the rating scale
            const MAX_RATING = 1300;
        
            let percent, startColor, endColor;
            
            if (rating <= MID_RATING) {
                // For ratings in the lower half, interpolate from red to green
                percent = (rating - MIN_RATING) / (MID_RATING - MIN_RATING);
                startColor = { r: 255, g: 0, b: 0 }; // Red
                endColor = { r: 0, g: 255, b: 0 }; // Green
            } else {
                // For ratings in the upper half, interpolate from green to cyan
                percent = (rating - MID_RATING) / (MAX_RATING - MID_RATING);
                startColor = { r: 0, g: 255, b: 0 }; // Green
                endColor = { r: 0, g: 255, b: 255 }; // Cyan
            }
        
            // Interpolate
            const r = Math.round(startColor.r + percent * (endColor.r - startColor.r));
            const g = Math.round(startColor.g + percent * (endColor.g - startColor.g));
            const b = Math.round(startColor.b + percent * (endColor.b - startColor.b));
        
            return `rgb(${r}, ${g}, ${b})`;
        }
        //later,  display the game history as a row of cards, showing the games id and time played, clickable for replays
        return (
            <div className="feature-card login-form">
                <h1>Leaderboard</h1>
                <div style = {{color: 'gold', fontSize: 'medium' }}>You must have at least 10 games to appear on the leaderboard</div>
                <div style = {{color: 'gold', fontSize: 'medium' }}>(You will always appear for yourself)</div>
                <div className= "leaderboard-container">
                    <table className="leaderboard">
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Username</th>
                                <th>Rating</th>
                                <th>Games Played</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.leaderboard.map((player, index) => (
                                <tr key={index} className='leaderboard-row'>
                                    <td>{index + 1}</td>
                                    <td>{player.username}</td>
                                    <td style={{ color: interpolateColor(player.rating * 40) }}>{(player.rating * 40).toFixed(0)}</td>
                                    <td>{player.gamesPlayed}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
        
    }
}

export default Home;
