import React, { Component } from 'react';
import Die from '../Die.js';
import './AnnouncementSystem.css';

class AnnouncementSystem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentMessageIndex: 0,
            showCenterCounter: true,
            messageVisible: false,
            showPopup: false,
            popupX: 0,
            popupY: 0,
        };
        this.centerCounterRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.messages !== this.props.messages && this.props.messages.length > 0) {
            this.fadeOutCenterCounter();
        }
    }

    fadeOutCenterCounter = () => {
        this.setState({ showCenterCounter: false }, () => {
            setTimeout(() => {
                this.fadeInMessage(0);
            }, 1000); // 1 second for the center counter to fade out
        });
    };

    fadeInMessage = (index) => {
        this.setState({ messageVisible: true });
        setTimeout(() => {
            this.showMessage(index);
        }, 2000); // 2 seconds for the message to fade in
    };

    showMessage = (index) => {
        if (index < this.props.messages.length) {
            this.setState({ currentMessageIndex: index });
            setTimeout(() => {
                // Fade out the message after its duration
                this.setState({ messageVisible: false });
                setTimeout(() => {
                    this.fadeInCenterCounter(index + 1);
                }, this.props.messages[index].duration + 2000); // Message duration + 2s for fade-out
            }, 1000); // 2 seconds after message becomes visible
        } else {
            this.fadeInCenterCounter(index);
        }
    };

    fadeInCenterCounter = (nextIndex) => {
        this.setState({ showCenterCounter: true }, () => {
            if (nextIndex < this.props.messages.length) {
                setTimeout(() => {
                    this.fadeInMessage(nextIndex);
                }, 1000); // Wait for center counter to fade in
            } else {
                this.props.onAllMessagesDisplayed();
            }
        });
    };

    handleMouseMove = (event) => {
        const counterRect = this.centerCounterRef.current.getBoundingClientRect();
    
        const relativeX = event.clientX - counterRect.left;
        const relativeY = event.clientY - counterRect.top;
    
        this.setState({
            showPopup: true,
            popupX: relativeX,
            popupY: relativeY
        });
    };

    handleMouseLeave = () => {
        this.setState({ showPopup: false });
    };


    render() {
        const { messages, numDice, palaficoRound } = this.props;
        const { currentMessageIndex, showCenterCounter, messageVisible } = this.state;
        const currentMessage = messages && messages.length > currentMessageIndex 
                                ? messages[currentMessageIndex].content 
                                : null;
    
        return (
            <div>
                <div className={`centerText ${messageVisible ? 'visible' : ''}`}>
                    {currentMessage ? currentMessage() : null}
                </div>
                <div ref={this.centerCounterRef} className={`centerCounter ${showCenterCounter ? 'visible' : ''}`}>
                    <span>{numDice}</span>
                    <Die dieValue={0} extraClass={`counterDie ${palaficoRound ? 'palafico' : ''}`} fillColor="#e8e3e4" onMouseMove={this.handleMouseMove}
                        onMouseLeave={this.handleMouseLeave} centerCounterRef={this.centerCounterRef}></Die>
                    {this.state.showPopup && palaficoRound &&
                        <div className="popup inverse-feature-card" style={{ left: `${this.state.popupX}px`, top: `${this.state.popupY}px` }}>
                        {/* Palafico round rules here */}
                        <span style={{color:"#994c0c"}}>Palafico</span>
                        <span className='popup-row'><Die dieValue="1" className="popup-die" ></Die><span>s are not wild. </span></span>
                        <span className='popup-row'>Players with more than 1 die cannot change the bid face</span>
                    </div>
                    }
                </div>
            </div>
        );
    }
    
}

export default AnnouncementSystem;
