import AppContext from '../AppContext';
import React, { Component } from 'react';



class Join extends Component {
    static contextType = AppContext;
  
    constructor(props) {
        super(props);
        this.state = {
          joinCode: '',  // Track the join code entered by the user
          errorText: '', // Track any error messages
        };
        this.apiUrl = process.env.REACT_APP_API_URL; 
    }

    
    handleJoin = async () => {
        try {
            const { token, joinGame } = this.context;
            const { joinCode } = this.state;

            const response = await fetch(`${this.apiUrl}/joinGame/${joinCode}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${token}`,
                },
            });

            const data = await response.json();

            if (!response.ok) {
                //if error code is 400, user is already in game
                if (response.status === 400) {
                    this.setState({ errorText: 'You are already in a game.' });
                    return;
                }
                //if its 406 the game is full
                if (response.status === 406) {
                    this.setState({ errorText: 'The game is full' });
                    return;
                }
                //if its 409 the game is already started
                if (response.status === 409) {
                    this.setState({ errorText: 'The game has already started' });
                    return;
                }

                throw new Error(data.error || 'Error joining game.');
            }
            
            joinGame(data.game.joinCode);

            // Update the UI to navigate to the Lobby
            this.props.updateSelectedMenuItem('Lobby');
        } catch (error) {
            console.error('Error joining game:', error.message);
            this.setState({ errorText: 'Error joining game. Please try again.' });
        }
    };

    render(){
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                this.handleJoin();
            }
        };

        return (
            <div className="feature-card login-form">
                <div className="form-group">
                <span>Join Code</span>
                    <input
                        className="form-field"
                        type="text"
                        value={this.state.joinCode}
                        onChange={(e) => this.setState({ joinCode: e.target.value })}
                        placeholder="Enter join code"
                        onKeyDown = {handleKeyPress}
                    />
                </div>
                <div style={{margin: "0 0 3vmin 0" }}>{this.state.errorText}</div>
                <button className="button btn-green" onClick={this.handleJoin}>Join</button>
            </div>
        );
    }
}

export default Join;